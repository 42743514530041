<template>
  <div v-if="!loading && market" class="saa-transactions-container">
    <account-role-title role="Admin" />
    <!-- <breadcrumbs /> -->
    <transactions-info :admin="true"></transactions-info>
    <span class="title">{{ $t("Transactions") }}</span>
    <v-tabs v-model="tab" show-arrows>
      <v-tab
          v-for="(tab, index) in tabs"
          :key="index"
          @click="$router.push({name:'admin-transactions', params:{tab:tab.route}, query:{t:new Date().getTime()}})"
      >
        <span>{{ $t(tab.title) }}</span>
      </v-tab>
      <!-- tabs item -->
      <v-tabs-items v-model="tab">
        <v-tab-item v-for="(tab, index) in tabs" :key="index">
          <!-- tab component -->
          <component v-if="tab.route == $route.params.tab" :is="tab.component" />
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </div>
</template>

<script>
import AdminTransactionsTable from "@/views/cabinet/transactions/admin/AdminTransactionsTable";
import TransactionsInfo from "@/views/cabinet/transactions/TransactionsInfo";
import {ref, watch, onBeforeMount, computed} from "@vue/composition-api/dist/vue-composition-api";
import {useRouter} from "@core/utils";
import store from '@/store';
import { getCurrentInstance } from '@vue/composition-api';

export default {
  name: "StoreTransactions",
  components: {
    AdminTransactionsTable,
    TransactionsInfo
  },
  setup() {
    const vm = getCurrentInstance().proxy
    const {route, router} = useRouter()
    const tabs = [
      {title: 'All', route: 'all', component: AdminTransactionsTable},
      {title: 'TopUps', route: 'top-ups', component: AdminTransactionsTable},
      {title: 'Orders', route: 'orders', component: AdminTransactionsTable},
      {title: 'Refunds', route: 'refunds', component: AdminTransactionsTable},
      {title: 'Payouts', route: 'payouts', component: AdminTransactionsTable},
      {title: 'MP-Profit', route: 'mp-profit', component: AdminTransactionsTable}
    ]
    const tab = ref(tabs.findIndex(tab => tab.route === route.value.params.tab))
    onBeforeMount(() => {
      if(!vm.$can('all products view')) {
        router.push({name: 'error-404'})
      }else {
        store.dispatch('market/getMarket')
      }
    })
    const market = computed(() => {
      if(!store.state.market.market) router.push({name: 'user-market', params:{tab:'all'}})
      return store.state.market.market
    })
    watch(() => route.value.params.tab,
      (newTab) => {
        tab.value = tabs.findIndex(tab => tab.route === newTab)
      }
    )
    return {
      tabs,
      tab,
      market,
      loading: computed(() => store.state.market.loading),
    }
  }
}
</script>
