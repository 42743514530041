<template>
  <v-data-table
      :headers="headers"
      :items="transactions"
      :options.sync="options"
      :server-items-length="totalTransactions"
      :loading="loading"
      :footer-props="{
        'items-per-page-options': $store.getters['nav/itemsPerPageOptions'],
        'disable-pagination': loading,
        'disable-items-per-page': loading,
      }"
      :mobile-breakpoint="100"
      class="unset-table-hover"
      @update:items-per-page="updateItemsPerPage"
      @update:page="getAdminTransactionsFromApi"
      @update:sort-by="getAdminTransactionsFromApi"
      :items-per-page="globalItemsPerPage"
      group-by="transaction_group"
  >
    <template v-slot:group.header="{items}">
      <th colspan="7">
        {{ 'group id: ' }}{{ items[0].transaction_group }}
        <v-chip
            small
            :color="typeColor[items[0].type]"
            :class="`${typeColor[items[0].type]}--text`"
            class="v-chip-light-bg"
        >
          {{ items[0].type }}
        </v-chip>
      </th>
    </template>
    <template v-slot:loading>
      <v-skeleton-loader type="table-row-divider@5" style="width: 90vw; max-width: 1390px"></v-skeleton-loader>
    </template>
    <template #[`item.transaction_info`]="{item}">
    <span v-if="item.type == 'Top Up'">
      {{ $t('Account top up transaction.') }}
    </span>
      <span v-if="item.type == 'Payout'">
      {{ $t('Store payout transaction.') }}
    </span>
      <span v-if="item.type == 'Refund to card'">
      {{ $t('Refund to card transaction.') }}
    </span>
      <span v-else-if="item.type == 'Order' || item.type == 'Refund'">
        <span v-if="item.type=='Order'">{{ $t('Ordered Item Transaction.') }} </span>
        <span v-else>{{ $t('Refund Item Transaction.') }} </span>
        <b>{{ 'Item:' }}</b>
         <router-link style="text-decoration: none" :to="{name: 'admin-order', params: {id: item.obj_id}}">
        {{ item.obj_id }}
      </router-link>
      </span>
    </template>
    <template #[`item.type`]="{item}">
      <span v-if="item.type == 'Payout'">
        <span v-if="item.journal_type == 'Market'">
            <b>{{ $t('Seller:') }}</b>
            <router-link style="text-decoration: none"
                        :to="{name: 'user', params: {id: item.transaction_info.seller_id, tab:'store'}}">
              {{ item.transaction_info.seller_title }}
            </router-link>
        </span>
        <span v-else>
          <b>{{$t('payout type:')}}</b> {{item.transaction_info.payout_type}}
        </span>
      </span>
      <span v-if="item.type == 'Top Up' && item.journal_type == 'User'">
        <b>{{ 'user:' }}</b>
        <router-link style="text-decoration: none" :to="{name: 'user', params: {id: item.transaction_info.customer_id}}">
          {{ item.transaction_info.customer_name }}
        </router-link>
      </span>
      <span v-if="item.type == 'Top Up' && item.journal_type == 'CompanyJournal'">
        <b>{{ 'payment method:'}}</b> {{item.transaction_info.pm_method}}
      </span>
      <span v-if="item.type == 'Refund to card' && item.journal_type == 'User'">
      <b>{{ 'user name:' }}</b>
      <router-link style="text-decoration: none" :to="{name: 'user', params: {id: item.transaction_info.customer_id}}">
        {{ item.transaction_info.customer_name }}
      </router-link>
    </span>
      <span v-if="item.type == 'Refund to card' && item.journal_type == 'CompanyJournal'">
        <b>{{ 'PM:'}}</b> {{item.transaction_info.pm_method}}
    </span>
      <span v-else-if="item.type == 'Order' || item.type == 'Refund'">
      <span v-if="item.journal_type == 'User'">
        <b>{{ $t('Buyer:') }}</b>
        <router-link style="text-decoration: none" :to="{name: 'user', params: {id: item.transaction_info.customer_id}}">
          {{ item.transaction_info.customer_name }}
        </router-link>
      </span>
        <span v-if="item.journal_type == 'Market'">
          <b>{{ $t('Seller:') }}</b>
          <router-link style="text-decoration: none"
                       :to="{name: 'user', params: {id: item.transaction_info.seller_id, tab:'store'}}">
            {{ item.transaction_info.seller_title }}
          </router-link>
        </span>
        <span v-else-if="item.journal_type == 'CompanyJournal'">
          <b>{{$t('MP Profit')}}</b>
        </span>
      </span>
    </template>
    <template #[`item.debit`]="{item}">
      {{ item.debit ? `-$${Number(item.debit)}` : '-' }}
    </template>
    <template #[`item.credit`]="{item}">
      {{ item.credit ? `$${Number(item.credit)}` : '-' }}
    </template>
  </v-data-table>
</template>

<script>
import {useVModel} from '@vueuse/core'
import {ref, watch, computed} from "@vue/composition-api";
import {useRouter} from '@core/utils'
import {truncate} from "@/plugins/helpers"
import axios from "@axios";
import store from '@/store'

export default {
  name: 'AdminTransactions',
  setup() {
    const {route} = useRouter()
    const options = computed(({
      get() {
        return store.state.pagination.adminTransactions[route.value.params.tab]
      },
      set(val) {
        let ob = {}
        let page = val.page > val.lastPage ? 1 : val.page
        ob[route.value.params.tab] = Object.assign({}, val, {
          page
        })
        store.commit("pagination/SET_ADMIN_TRANSACTIONS_PAGINATION", ob);
      }
    }))
    const loading = ref(true)
    const transactions = ref([])
    const totalTransactions = ref(0)

    const getAdminTransactionsFromApi = () => {
      loading.value = true
      const {groupBy, groupDesc, multiSort, mustSort, page, sortBy, sortDesc} = options.value
      axios.get('admin/transactions', {
        params: {
          pagination: globalItemsPerPage.value,
          page,
          sort_field: sortBy && sortBy[0] ? sortBy[0] : [],
          sort_dir: sortBy && sortDesc[0] ? 'ASC' : 'DESC',
          type_tab: route.value.params.tab,
        }
      }).then(res => {
        transactions.value = res.data.data
        totalTransactions.value = res.data.meta.total
        let ob = {}
        ob[route.value.params.tab] = {itemsPerPage: globalItemsPerPage.value, page, lastPage: res.data.meta.last_page}
        store.commit("pagination/SET_ADMIN_TRANSACTIONS_PAGINATION", ob)
        loading.value = false
      }).catch(e => console.log(e))
    }

    const globalItemsPerPage = computed(() => store.getters['nav/globalItemPerPage'])

    const updateItemsPerPage = (e) => {
      store.commit("nav/SET_GLOBAL_ITEMS_PER_PAGE", e)
      getAdminTransactionsFromApi()
    }

    getAdminTransactionsFromApi()

    return {
      updateItemsPerPage,
      getAdminTransactionsFromApi,
      globalItemsPerPage,
      options,
      loading,
      transactions,
      totalTransactions,
      truncate,
      typeColor: {
        'Top Up': 'success',
        Order: 'primary',
        Refund: 'error',
        'Refund to card': 'error',
        Payout: 'success'
      },
      headers: [
        {text: 'Id', value: 'id', width: 340, sortable: false},
        {text: 'Date', value: 'post_date', width: 115, sortable: false},
        {text: 'Journal', value: 'type', sortable: false},
        {text: 'Description', value: 'transaction_info', sortable: false},
        {text: 'Received', value: 'credit', sortable: false, width: 90},
        {text: 'Spend', value: 'debit', sortable: false, width: 90},
      ],
    }
  }
}
</script>